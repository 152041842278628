import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';
import cogoToast from 'cogo-toast';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr from 'react-flatpickr';
import { useForm } from 'react-hook-form';
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import ButtonIcon from '../../../components/common/ButtonIcon';
import MainTable from '../../../components/tables/MainTable';
import { EnvironmentContext } from '../../../context/Context';
import paymentsType from '../../../data/recaudo/paymentsType';
import { getTodayStr } from '../../../helpers/dateFormatters';
import { currencyFormat, formatterId } from '../../../helpers/formatters';
import numToWord from '../../../helpers/numToWord';
import { getLocaleCalendarConfig } from '../../../helpers/utils';
import { desembolsoServices, generalServices, recaudoServices } from '../../../services';
import { RecaudoContext } from '../RecaudoContext';
import RecaudoInvoice from '../invoice/RecaudoInvoice';

function RecaudoClientModal({ open, toggle, data }) {
  const context = useContext(RecaudoContext);
  const environment = useContext(EnvironmentContext);
  const { client } = context;
  const { register, setValue, getValues, errors, watch } = useForm({
    defaultValues: {
      paymentType: 1
    }
  });

  const [requesting, setRequesting] = useState();
  const [dataTable, setDataTable] = useState([]);
  const watchPaymentType = watch("paymentType");
  const [treasuries, setTreasuries] = useState([]);
  const [destAccounts, setDestAccounts] = useState([]);
  const [banks, setBanks] = useState([]);
  const [cashConcepts, setCashConcepts] = useState([]);

  const [invoiceProps, setInvoiceProps] = useState();
  const [success, setSuccess] = useState();

  const today = new Date();

  useEffect(() => {
    loadBanks();
    loadTreasuries();
    loadCashConcepts();
    loadBankAccounts();
  }, [environment])

  useEffect(() => {
    if (!data) return;
    setSuccess(null);
    setInvoiceProps(null);
    loadDataTable();
  }, [data])

  const columns = [
    {
      dataField: 'periodo',
      text: 'Periodo',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: '',
      text: 'Fecha Recaudo',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (ignore, credit) => <Flatpickr
        className="form-control text-right pr-2"
        placeholder='aaaa/mm/dd'
        options={{
          dateFormat: "Y/m/d",
          //maxDate: today,
          locale: getLocaleCalendarConfig(),
        }}
        onChange={(date, dateStr) => {
          credit.fecha = dateStr.replaceAll("/", "-");
        }}
      />
    },
    {
      dataField: 'recaudo',
      text: 'Valor Recaudo',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (recaudo, selected, index) => currencyInputFormatter(selected, index),
    }/*,
    {
      dataField: 'interes',
      text: 'Interés',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (dataField) => moneyFormatter(dataField),
    },
    {
      dataField: 'capital',
      text: 'Capital',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 text-left py-2 align-middle',
      formatter: (dataField) => moneyFormatter(dataField),
    },
    {
      text: 'Acción',
      dataField: '',
      headerClasses: 'border-0',
      text: 'Acción',
      classes: 'border-0 py-2 align-middle',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(selected),
      align: 'center',
      headerAlign: 'center'
    }*/
  ]

  const moneyFormatter = value => {
    return (
      <CountUp end={value} duration={1} prefix="$" separator="." decimal="," decimals={2} style={{ fontSize: '1rem' }} />
    )
  }

  const currencyInputFormatter = (credit, index) => {
    return (
      <div className='pr-4'>
        <CurrencyInput
          className="form-control pl-2"
          allowNegativeValue={false}
          name="recaudoValue"
          id="recaudoValue"
          value={credit.recaudo}
          prefix='$'
          intlConfig={{ locale: 'es-CO', currency: 'COP' }}
          onValueChange={value => onChangeRecaudo(value, credit, index)}
          decimalScale={2}
          maxLength={20}
          placeholder="$ 0.00"
        />
      </div>
    )
  }

  /*const actionsFormatter = (credit) => {
    return (
      <div>
        <IconButton
          onClick={() => {
            handleReset(credit)
          }}
        >
          <FontAwesomeIcon
            style={{ width: '12px' }}
            icon={faTrash}
            color="red"
          />
        </IconButton>
      </div>
    )
  }

  const handleReset = () => {
    loadDataTable();
  }*/

  const onChangeRecaudo = (value, credit, index) => {
    if (index == 0 || dataTable[index - 1].recaudo != null) {
      let valueParsed = !value ? 0 : parseFloat(value.replaceAll(',', '.'));
      credit.recaudo = valueParsed || null;
      credit.capital = (valueParsed || 0) - credit.interes;
    } else if (value) {
      credit.recaudo = "";
      cogoToast.error("Diligencie el pago anterior primero",
        { position: 'bottom-left', heading: 'Error' });
    }
    clearTimeout(window.anterior);
    if (credit.recaudo) {
      window.anterior = setTimeout(() => setDataTable([...dataTable]), 500);
    } else {
      setDataTable([...dataTable]);
    }
  }

  const loadDataTable = recaudo => {
    /*const recaudoValue = recaudo !== undefined ? recaudo : 0
    const intereses = recaudoValue >= data.saldo_interes
      ? data.saldo_interes
      : recaudoValue;
    const capital = recaudoValue > data.saldo_interes
      ? recaudoValue - data.saldo_interes
      : 0;
    const tempData = {
      idx: 1,
      intereses,
      capital,
      recaudo: recaudoValue
    }*/

    var lista = [];
    if (data.omitidos) {
      var omitidos = JSON.parse(data.omitidos);
      for (var i = 0; omitidos[i] < data.periodoPago && i < omitidos.length; i++) {
        lista.push({
          periodo: omitidos[i]
        });
      }
    }
    var amortizable = data.tipoFormula == 1;
    var saldo = data.saldopro - data.saldo_interes;
    var tasa = data.tasa / 100;
    var interesFijo = data.monto * tasa;
    for (var i = data.periodoActual > data.periodoPago ? data.periodoPago + 1 : data.periodoPago || 1; i <= (data.periodoActual || 1); i++) {
      var interes = data.periodoActual == data.periodoPago ? data.saldo_interes : amortizable ? saldo * tasa : interesFijo;
      lista.push({
        periodo: i,
        interes: interes,
        capital: -interes,
      });
      saldo += interes;
    }
    setDataTable(lista);
  };

  const loadTreasuries = async () => {
    if (!environment.treasuries) return;
    const tempTreasuries = environment.treasuries.map(treasury => ({
      value: treasury.tesoreria,
      label: treasury.nombre
    }))
    setTreasuries(tempTreasuries);
  }

  const loadCashConcepts = async () => {
    if (!environment.cashConcepts) return;
    const tempCashConcepts = environment.cashConcepts.map(concept => ({
      value: concept.conceptotesoreria,
      label: concept.nombre
    }))
    setCashConcepts(tempCashConcepts);
  }

  const loadBankAccounts = async () => {
    const response = await desembolsoServices.getBankAccount();

    if (!response || response.error || !response.objResponse) {
      cogoToast.error(response.msg || "No se logro cargar los datos de las cuentas bancarias",
        { position: 'bottom-left', heading: 'Error' });
      return;
    }
    const tempDestAccounts = response.objResponse.map(account => ({
      value: account.idCuenta,
      label: `${account.nroCuenta} - ${account.nombreBanco}`
    }))

    setDestAccounts(tempDestAccounts);
  }

  const loadBanks = async () => {
    const response = await generalServices.getBanks();
    if (!response || response.error) {
      cogoToast.error(response.msg || "No se logro cargar los datos de los bancos",
        { position: 'bottom-left', heading: 'Error' });
      return;
    }

    const tempBanks = response.map(bank => ({
      value: bank.id,
      label: bank.nombre
    }))
    setBanks(tempBanks);
  }

  const createInvoiceProps = (payload) => {
    const selectedConcept = payload.isCash
      ? cashConcepts.filter(concept => concept.value == payload.conceptoTesoreria)
      : [];
    const montoWord = numToWord(payload.valorRecaudo)

    const tempOwnProps = {
      client: data.nombreTercero,
      clientTypeId: client.terceroInformacion.tipoIdentificacion,
      clientId: client.terceroInformacion.identificacion ? formatterId(Number(client.terceroInformacion.identificacion)) : "",
      serial: payload.consecutivo,
      ciudad: payload.ciudad,
      idCredit: data.credito,
      amount: currencyFormat(payload.valorRecaudo || 0),
      concept: selectedConcept[0] ? selectedConcept[0].label : "",
      sumWord: montoWord || "",
      checkCash: payload.isCash,
      checkWireTransfer: payload.isWireTransfer,
      checkPSE: false,
      checkCheque: payload.isCheque,
      date: payload.fecha ? payload.fecha.split('-') : getTodayStr().split('-'),
      observations: payload.observacion
    }

    setInvoiceProps(tempOwnProps)
  }

  const onChange = async e => {
    let { name, value } = e;
    setValue(name, value);
  }

  const onRecaudo = async () => {
    setRequesting(true);
    const generalData = getValues();

    if (!generalData.cashConcept) {
      cogoToast.warn("Por favor complete los campos requeridos", { position: 'bottom-left', heading: 'Campos requeridos' });
      setRequesting(false);
      return;
    }

    /*if (watchPaymentType == 1 && (!generalData.cashSource)) {
      cogoToast.warn("Por favor complete los campos requeridos para efectivo", { position: 'bottom-left', heading: 'Campos requeridos' });
      setRequesting(false);
      return;
    }
 
    if (watchPaymentType == 2 && !generalData.destAccount) {
      cogoToast.warn("Por favor selecciona una cuenta de destino", { position: 'bottom-left', heading: 'Campos requeridos' });
      setRequesting(false);
      return;
    }
 
    if (watchPaymentType == 3 && (!generalData.chequeBank || !generalData.numCheque)) {
      cogoToast.warn("Por favor completa la información del cheque", { position: 'bottom-left', heading: 'Campos requeridos' });
      setRequesting(false);
      return;
    }*/

    var request = [];
    for (var i = 0; i < dataTable.length; i++) {
      var r = dataTable[i];
      if (r.recaudo == null && !r.fecha) {
        break;
      }
      if (r.recaudo && !r.fecha) {
        cogoToast.warn("Por favor especifique la fecha de recaudo del periodo " + r.periodo, { position: 'bottom-left', heading: 'Campos requeridos' });
        setRequesting(false);
        return;
      }
      if (r.fecha && r.recaudo == null) {
        cogoToast.warn("Por favor especifique el valor por recaudar del periodo " + r.periodo, { position: 'bottom-left', heading: 'Sin valor a recaudar' });
        setRequesting(false);
        return;
      }
      request.push({
        periodo: r.periodo,
        fecha: r.fecha,
        idPrestamo: data.prestamo,
        valorRecaudo: r.recaudo,
        abonoInteres: r.recaudo > r.interes ? r.interes : r.recaudo,
        abonoCapital: r.recaudo > r.interes ? r.recaudo - r.interes : 0,
        tipoRecaudo: 1,
        modalidadPago: watchPaymentType,
        idTesoreria: generalData.cashSource ? Number(generalData.cashSource) : null,
        conceptoTesoreria: generalData.cashConcept ? Number(generalData.cashConcept) : 0,
        idCuentaDestino: generalData.destAccount ? Number(generalData.destAccount) : 0,
        idBancoCheque: generalData.chequeBank ? Number(generalData.chequeBank) : 0,
        numeroCheque: generalData.numCheque || '',
        observacion: generalData.observations
      });
    }
    if (!request.length) {
      cogoToast.warn("Se debe diligenciar al menos un registro", { position: 'bottom-left', heading: 'Sin valor a recaudar' });
      setRequesting(false);
      return;
    }

    /*const payload = {
      fecha: recaudoDate || getTodayStr(),
      idPrestamo: data.prestamo,
      valorRecaudo: recaudoData.recaudo,
      abonoInteres: recaudoData.intereses,
      abonoCapital: recaudoData.capital,
      tipoRecaudo: 1,
      modalidadPago: watchPaymentType,
      idTesoreria: generalData.cashSource ? Number(generalData.cashSource) : null,
      conceptoTesoreria: generalData.cashConcept ? Number(generalData.cashConcept) : 0,
      idCuentaDestino: generalData.destAccount ? Number(generalData.destAccount) : 0,
      idBancoCheque: generalData.chequeBank ? Number(generalData.chequeBank) : 0,
      numeroCheque: generalData.numCheque || '',
      observacion: generalData.observations
    }*/

    const isCash = generalData.cashSource && generalData.cashConcept;
    const isWireTransfer = !!generalData.destAccount;
    const isCheque = generalData.chequeBank && generalData.numCheque;

    const response = await recaudoServices.setRecaudo(request);
    if (!response || response.error) {
      cogoToast.error(response.msg || "No se logro completar la solicitud de recaudo",
        { position: 'bottom-left', heading: 'Error' });
      setRequesting(false);
      return;
    }

    if (request.length == 1) {
      createInvoiceProps({
        ...request[0],
        isCash,
        isWireTransfer,
        isCheque,
        consecutivo: response.objResponse.consecutivo,
        ciudad: response.objResponse.ciudad
      });
    }
    setSuccess(true);
    setRequesting(false);
  }

  const onOk = () => {
    setInvoiceProps(null);
    setSuccess(false);
    toggle(true);
  }

  return (
    <Modal size="lg" isOpen={open}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={onOk}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
        {`Recaudo Crédito - ${data ? data.credito : ''}`}
        <div className='text-primary' style={{ fontSize: '0.8em' }}>{data ? data.nombreTercero : ''}</div>
      </ModalHeader>
      <ModalBody className="p-0">
        {requesting
          ? (
            <Row className="py-4 d-flex justify-content-center h-100">
              <Spinner color="primary" />
            </Row>
          ) : (
            <>
              {success ? (
                <div className="text-center mt-4">
                  <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success mr-1 fs-8" />
                  <h6 className="fs-2 m-3">
                    Recaudo Exitoso!
                  </h6>
                  <p className="fs--1 mb-0"></p>
                  {invoiceProps /*&& invoiceProps.checkCash*/ && (
                    <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
                      <PDFDownloadLink document={<RecaudoInvoice title="Recibo de caja" data={invoiceProps} />} fileName={`recibo_caja-${invoiceProps.serial}.pdf`} >
                        {({ blob, url, loading, error }) =>
                          loading ? 'Cargando recibo...' : 'Descargar recibo de caja'
                        }
                      </PDFDownloadLink>
                    </ButtonIcon>
                  )}
                </div>
              ) : (
                <>
                  <form className='p-3'>
                    {false && paymentsType && paymentsType.map(paymentType => (
                      <Row className="m-0 w-100 justify-content-between p-2 rounded bg-light px-3">
                        <FormGroup
                          tag={CustomInput}
                          type="radio"
                          id={paymentType.name}
                          name="paymentType"
                          label={<strong>{paymentType.name}</strong>}
                          value={paymentType.id}
                          className="m-0"
                          innerRef={register({
                            required: true,
                          })}
                          checked={watchPaymentType == paymentType.id}
                          onChange={({ target }) => onChange(target)}
                        >
                        </FormGroup>
                      </Row>
                    ))}
                    {false && watchPaymentType && watchPaymentType == 1 && (
                      <Fragment>
                        <DetailRow title="Tesorería" className="m-0 mt-4">
                          <FormGroup>
                            <WizardInput
                              className="pl-2"
                              type="select"
                              tag={CustomInput}
                              placeholder="Seleccionar"
                              id="cashSource"
                              name="cashSource"
                              options={treasuries}
                              onChange={(e) => onChange({ name: 'cashSource', value: e.target.value })}
                              errors={errors}
                              innerRef={register({
                                required: true,
                              })}
                            />
                          </FormGroup>
                        </DetailRow>
                      </Fragment>
                    )}

                    {false && watchPaymentType && watchPaymentType == 2 && (
                      <>
                        <DetailRow title="Cuenta y Banco Destino" className="m-0 mt-4">
                          <FormGroup>
                            <WizardInput
                              className="pl-2"
                              type="select"
                              tag={CustomInput}
                              placeholder="Seleccionar"
                              id="destAccount"
                              name="destAccount"
                              options={destAccounts}
                              onChange={(e) => onChange({ name: 'destAccount', value: e.target.value })}
                              errors={errors}
                              innerRef={register({
                                required: true,
                              })}
                            />
                          </FormGroup>
                        </DetailRow>
                      </>
                    )}
                    {false && watchPaymentType && watchPaymentType == 3 && (
                      <>
                        <DetailRow title="Banco" className="m-0 mt-4">
                          <FormGroup>
                            <WizardInput
                              className="pl-2"
                              type="select"
                              tag={CustomInput}
                              placeholder="Seleccionar"
                              id="chequeBank"
                              name="chequeBank"
                              options={banks}
                              onChange={(e) => onChange({ name: 'chequeBank', value: e.target.value })}
                              errors={errors}
                              innerRef={register({
                                required: true,
                              })}
                            />
                          </FormGroup>
                        </DetailRow>
                        <DetailRow title="Numero del Cheque" className="m-0">
                          <FormGroup>
                            <Input
                              id="numCheque"
                              name="numCheque"
                              onChange={(e) => onChange({ name: 'numCheque', value: e.target.value }, true)}
                              {...register("numCheque", {
                                required: true,
                              })}
                            />
                          </FormGroup>
                        </DetailRow>
                        <DetailRow title="Cuenta Bancaria Propia" className="m-0">
                          <FormGroup>
                            <WizardInput
                              className="pl-2"
                              type="select"
                              tag={CustomInput}
                              placeholder="Seleccionar"
                              id="destAccount"
                              name="destAccount"
                              options={destAccounts}
                              onChange={(e) => onChange({ name: 'destAccount', value: e.target.value })}
                              errors={errors}
                              innerRef={register({
                                required: true,
                              })}
                            />
                          </FormGroup>
                        </DetailRow>
                      </>
                    )}

                    <DetailRow title="Concepto" className="m-0">
                      <FormGroup>
                        <WizardInput
                          className="pl-2"
                          type="select"
                          tag={CustomInput}
                          placeholder="Seleccionar"
                          id="cashConcept"
                          name="cashConcept"
                          options={cashConcepts}
                          onChange={(e) => onChange({ name: 'cashConcept', value: e.target.value })}
                          errors={errors}
                          innerRef={register({
                            required: true,
                          })}
                        />
                      </FormGroup>
                    </DetailRow>
                    <Row>
                      <Col>
                        <DetailRow title="Último periodo pagado" className="m-0">
                          {data?.periodoPago || "Ningún pago"}
                        </DetailRow>
                      </Col>
                      <Col>
                        <DetailRow title="Último periodo pendiente de pago" className="m-0">
                          {data?.periodoActual} ({data?.periodoActual > data?.periodoPago ? (data?.periodoActual - data?.periodoPago) + " cuotas pendientes" : "No tiene coutas pendientes"})
                        </DetailRow>
                      </Col>
                      <Col>
                        <DetailRow title="Fecha corte inicial" className="m-0">
                          {data?.fechaCorte}
                        </DetailRow>
                      </Col>
                    </Row>
                  </form>
                  <MainTable
                    keyField="periodo"
                    data={dataTable}
                    columns={columns}
                    pageSize={12}
                    className='mb-0 shadow-none'
                    rowClasses="fs--3"
                    headerClassName='bg-light'
                    noSearch
                    noAdd
                  />
                  <Row className="m-0 w-100 justify-content-between p-2 rounded bg-light px-3">
                    <FormGroup>
                      <strong>Observaciones: </strong>
                      <Input
                        type="textarea"
                        id="observations"
                        maxLength={500}
                        name="observations"
                        onChange={(e) => onChange({ name: 'observations', value: e.target.value }, true)}
                        {...register("observations", {
                          required: true,
                        })}
                      />
                    </FormGroup>
                  </Row>
                </>
              )}

            </>
          )
        }
      </ModalBody >
      <ModalFooter className="no-border">
        {success ? (
          <Button onClick={onOk} transform="shrink-3 down-2" color="primary" >
            Aceptar
          </Button>
        ) : (
          <Col className="text-right" >
            <Button onClick={() => toggle()} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
              Cancelar
            </Button>
            <Button onClick={onRecaudo} transform="shrink-3 down-2" color="primary" >
              Recaudar
            </Button>
          </Col>
        )}
      </ModalFooter>
    </Modal >
  )
}

export default RecaudoClientModal

