import { faCheck, faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import {
  Card,
  CardBody,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Row
} from 'reactstrap';

import WizardInput from '../../../components/auth/wizard/WizardInput';
import ButtonIcon from '../../../components/common/ButtonIcon';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import { EnvironmentContext } from '../../../context/Context';

const LoteInfo = ({
  title,
  noButtons,
  context: sourceContext,
  loteSelected,
  listLotes,
  handleCleanLote,
  handleDateRange,
  handleChangePag,
  handleChangeLote,
  showDatePicker,
  handleSaveClick,
}) => {
  const environment = useContext(EnvironmentContext);
  const context = useContext(sourceContext);

  const { loadCreditsByPag, pagSelected, toggleModalLote } = context;
  const [listPagadurias, setListPagadurias] = useState([]);

  const [dateRange, setDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 8)), new Date()]);
  const [startDate, endDate] = dateRange;

  const [amount, setAmount] = useState("");
  const [cant, setCant] = useState("");

  useEffect(() => {
    if (!environment) return;
    parsePagadurias();
  }, [environment])

  useEffect(() => {
    if (!dateRange || !handleDateRange) return;
    handleDateRange(dateRange);
  }, dateRange)

  const parsePagadurias = () => {
    if (!environment.pagadurias) return;
    const tempListPagadurias = environment.pagadurias.map(pagaduria => ({
      value: pagaduria.id,
      label: pagaduria.nombreComercial || pagaduria.razonSocial
    }));

    setListPagadurias(tempListPagadurias);
  };

  const onChangePag = data => {
    if (!environment || !environment.pagadurias) return;
    if (!data || !data.value) {
      loadCreditsByPag(null);
      return;
    };
    const pagIndex = environment.pagadurias.findIndex(p => p.id == data.value);
    if (pagIndex < 0) loadCreditsByPag(null);
    loadCreditsByPag(environment.pagadurias[pagIndex])
    if (handleChangePag) {
      handleChangePag(environment.pagadurias[pagIndex])
    }
  }

  const onChangeLote = data => {
    if (!listLotes) return;
    if (!handleChangeLote) return;
    if (!data || !data.value) {
      handleChangeLote();
      return;
    };
    const pagIndex = listLotes.findIndex(l => l.value == data.value);
    handleChangeLote(listLotes[pagIndex]);
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      onClick={onClick}
      ref={ref}
      placeholder="Rango de fechas"
      value={value}
    />
  ));

  const handleSave = () => {
    if (handleSaveClick) {
      handleSaveClick({
        cant,
        amount
      })
    } else {
      toggleModalLote();
    }
  }

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title={title || "Lote a Recaudar"}>
        <div className='d-flex'>
          {showDatePicker && (
            <div className='mx-2' style={{ minWidth: '250px' }}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                locale="es"
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                customInput={<ExampleCustomInput />}
              />
            </div>
          )}
          {!noButtons && (
            <>
              <ButtonIcon onClick={handleCleanLote} icon={faTimes} transform="shrink-3 down-2" color="falcon-default" size="sm" className="mr-2">
                Cancelar
              </ButtonIcon>
              {/*<ButtonIcon onClick={() => console.log("PRE-GUARDAR")} icon={faCheckCircle} transform="shrink-3 down-2" color="falcon-default" size="sm" className="mr-2">
                Pre-Guardar
              </ButtonIcon>*/}
              <ButtonIcon onClick={handleSave} icon={faCheck} transform="shrink-3 down-2" color="primary" size="sm" className="mr-2">
                Guardar
              </ButtonIcon>
            </>
          )}
        </div>
      </FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        <Row>
          <Col lg={6}>
            <DetailRow title="Pagaduría" className="m-0">
              <FormGroup>
                <WizardInput
                  className="pl-2"
                  type="select"
                  tag={CustomInput}
                  value={pagSelected ? pagSelected.id : ''}
                  placeholder="Seleccionar"
                  id="pagaduria"
                  name="pagaduria"
                  options={listPagadurias}
                  onChange={(e) => onChangePag({ name: 'pagaduria', value: e.target.value })}
                  errors={[]}
                />
              </FormGroup>
            </DetailRow>
            {listLotes && (
              <DetailRow title="Lote" className="m-0">
                <FormGroup>
                  <WizardInput
                    className="pl-2"
                    type="select"
                    tag={CustomInput}
                    value={loteSelected ? loteSelected.value : ''}
                    placeholder="Seleccionar"
                    id="lote"
                    name="lote"
                    options={listLotes}
                    onChange={(e) => onChangeLote({ name: 'lote', value: e.target.value })}
                    errors={[]}
                  />
                </FormGroup>
              </DetailRow>
            )}
          </Col>
          {!listLotes && (
            <>
              <Col lg={2}>
                <DetailRow title="Cantidad esperada" className="m-0">
                  <FormGroup>
                    <CurrencyInput
                      className="form-control"
                      allowNegativeValue={false}
                      name="bonus"
                      id="bonus"
                      onValueChange={(v) => setCant(v)}
                      decimalScale={0}
                      maxLength={5}
                      placeholder="0" />
                  </FormGroup>
                </DetailRow>
              </Col>
              <Col>
                <DetailRow title="Valore total esperado" className="m-0">
                  <FormGroup>
                    <CurrencyInput
                      className="form-control"
                      allowNegativeValue={false}
                      name="amount"
                      id="amount"
                      prefix='$'
                      intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                      onValueChange={(v) => setAmount(v)}
                      decimalScale={2}
                      maxLength={20}
                      placeholder="$ 0.00" />
                  </FormGroup>
                </DetailRow>
              </Col>
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default LoteInfo;
